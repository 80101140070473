import * as React from 'react';
import { NextPage } from 'next';
import { ArrowRightIcon } from '@heroicons/react/solid';
import Head from 'components/Head';
import PageLayout from 'layouts/Website';
import TopText from 'components/Website/TopText';
import LandingHeroSection from 'components/Website/LandingHero';
import StatsSection from 'components/Website/StatsSection';
import FeaturedSection from 'components/Website/FeatureSection';
import AwardBlock from 'components/Website/AwardBlock';
import LandingQuote from 'components/Website/LandingQuote';
import CallOutSection from 'components/Website/CallOutSection';
import DifferentiatorSection from 'components/Website/DifferentiatorSection';
import TradingSystemTabs from 'components/Website/TradingSystem';
import Testimonials from 'components/Website/Testimonials';
import PricingCardsComponent from 'components/Website/Pricing';
import PricingGrid from 'components/Website/PricingCards/PricingGrid';
import TrackerItems from 'components/Website/SmartMoney';
// import DifferentiatorSectionCrypto from 'components/blocks/Website/DifferentiatorSectionCrypto';
import NewProduct from 'components/Website/NewProductBlock';
import NewsLetterBlock from 'components/Website/NewsLetter';
import TookitSection from 'components/Website/ToolKitSection';
import CompetitorComparison from 'components/CompetitorComparison';
import { CommunityPlan } from 'components/CommunityPlan';
import Link from 'components/Link';
import TopBanner from 'components/TopBanner';
import GetInTouch from 'components/GetInTouch';
import { useShowBannerConditions } from 'hooks/useShowBanner';

import { TIME_DELAY, SHOW_BANNER_CONDITION } from 'constants/topBannerConfig';
import {
  DASHBOARD_SIGNUP_MONTHLY,
  DASHBOARD_SIGNUP_QUARTERLY,
  DASHBOARD_SIGNUP_YEARLY,
  OPTION_FLOW_PAGE,
} from 'constants/pages';

const stats = [
  { id: 1, name: 'Traders interested in our tool', value: '185k+' },
  { id: 2, name: 'Traders benefited from our paid service', value: '12k+' },
  { id: 3, name: 'Traders learned from our free resources', value: '27k+' },
  { id: 4, name: 'Trade ideas discovered on our platform', value: '719k+' },
];

const landingQuote =
  'Institutional investors (smart money) only trade when they have an unfair advantage. We analyze millions of dark pool prints and options contracts traded each day to alert you on the tickers that have the highest potential of being market moving.';

const tabs = [
  {
    label: 'Option Flow + Dark Pool Dashboard',
    desc: 'Real-time live feed of the highest conviction smart money trades with smart highlighting for unusual activity and urgency',
    content: (
      <div className="text-center">
        <img src="/images/dashboard-p-1080.png" className="rounded-md" />
        <p className="mx-auto mt-6  text-sm">
          Intelligent order flow to see every unusual options trade, sneaky dark pool print, and
          smart money transaction in real-time, ranked by our proprietary algorithm for high
          potential
        </p>
      </div>
    ),
  },
  {
    label: 'Complete Technical Analysis',
    desc: 'Comprehensive and easy to use system to accurately predict when to buy & sell stocks, options, forex, and futures',
    content: (
      <div className="text-center">
        <p>
          <img src="/images/technical-analysis.png" className="rounded-md" />
          <p className="mx-auto mt-6 text-sm">
            Enter every trade with confidence knowing an elaborate series of indicators is
            reinforcing every buy/sell signal. No more guesswork!
          </p>
        </p>
      </div>
    ),
  },
  {
    label: 'Algorithmic Trade Ideas + Breakout Signals',
    desc: 'Real-time algorithmic trade ideas and breakout signals with a proven track record of strong performance',
    content: (
      <div className="text-center">
        <img src="/images/signals.png" className="rounded-md" />
        <div className="mx-auto mt-6 max-w-2xl  text-center">
          <div className="text-[15px]">
            Effortlessly find high potential trade ideas algorithmically curated using unbiased,
            data-driven strategy.
          </div>
          <ol className="list-inside list-decimal text-center text-[15px]">
            <li className="my-5">
              Real-time access to high potential unusual, sweep, and momentum tickers
            </li>
            <li className="my-5">
              Breakout signals for stocks, crypto, and forex when multiple proprietary technical
              indicators show a similar pattern
            </li>
          </ol>
        </div>
      </div>
    ),
  },
  {
    label: 'Stocks, Crypto, & Forex News Sentiment + Market Scanner',
    desc: 'Never trade blindly with institutional-grade market scanner and trending news sentiment analysis from 100+ premium sources',
    content: (
      <div className="text-center">
        <p>
          <img src="/images/crypto-p-1080.png" className="rounded-md" />
          <p className="mx-auto mt-6 text-sm">
            Powerful market scanner and premium news sentiment analysis with unique, actionable
            charts to see the big picture before you trade.
          </p>
        </p>
      </div>
    ),
  },
  {
    label: 'Industry Leading Ticker Analysis with Actionable Charts & Data',
    desc: 'Easily find high-conviction trades with proprietary indicators, trends analysis, and news sentiment',
    content: (
      <div className="text-center">
        <p>
          <img src="/images/ticker-research-p-1080.png" className="rounded-md" />
          <p className="mx-auto mt-6 text-sm">
            Unmatched market context, aggregated by ticker, coupled with unique, actionable charts
            help you see the big picture and maximize your chance of success on every trade
          </p>
        </p>
      </div>
    ),
  },
];

const PRICING_TIER = [
  {
    name: 'Monthly',
    id: 'tier-monthly',
    href: DASHBOARD_SIGNUP_MONTHLY,
    price: 75,
    description: <div className="text-sm">Great Value. $75 Billed Monthly.</div>,
    discountphrase: 'Less than the profit of a single trade',
    discount: 24,
    mostPopular: false,
    border: 'from-blue-500 to-blue-500',
  },
  {
    name: 'Quarterly',
    id: 'tier-quarterly',
    href: DASHBOARD_SIGNUP_QUARTERLY,
    price: 65,
    description: (
      <div className="text-sm">
        16% Discount. <span className="line-through">$220</span> $195 Billed Quarterly.
      </div>
    ),
    discount: 60,
    discountphrase: 'Save $120/Year',
    mostPopular: false,
    border: 'from-blue-500 to-teal-500',
  },
  {
    name: 'Annual',
    id: 'tier-annual',
    href: DASHBOARD_SIGNUP_YEARLY,
    price: 55,
    description: (
      <div className="text-sm">
        25% Discount. <span className="line-through">$900</span> $660 Billed Yearly.
      </div>
    ),
    discountphrase: 'Save $240/Year',
    discount: 240,
    mostPopular: true,
    border: 'from-teal-500 to-teal-500',
  },
];

const ROW_NAMES = [
  'Real-Time Options Order Flow',
  'Dark Pool & Equity Prints',
  'Crypto & Forex Market Scanner + News',
  'Robust Technical Analysis',
  'Live Trade Ideas With Proven Track Record',
  'Unusual Options Activity + Sweep Activity',
  'Unmatched Market Context & Sentiment',
  'Actionable Charts To Visualize Flow',
  'Industry Leading Analysis For Every Ticker',
  'Proprietary Indicators & Signals',
  'One-Click Dashboard Filtering',
  'Real-Time News + Sentiment',
  'On-Demand Historical Data',
  'Custom Watchlist',
  'Discord Access',
];

const calloutHeading = 'Gain Your Edge with Powerful Data-Driven Tools';
const calloutSubheading =
  'Trade faster and more confidently with robust and intuitive tools designed to increase your success';
const trackerCalloutHeading =
  'Institutional investors (smart money) use their access to insider information and sophisticated technology to move markets with an unfair advantage. Our institutional-grade tools simplify complex analysis to level the playing field for every trader.';

const trackerStats = [
  {
    id: 1,
    name: 'Unusual Activity',
    desc: '“Out of the ordinary” options trades bought with unusual volume and/or trade size, filled with extreme urgency ahead of extraordinary movements',
    logo: '/images/market-tracker.png',
  },
  {
    id: 2,
    name: 'Intermarket Sweeps',
    desc: '“Stealthy”, urgent orders the Smart Market tries to hide by “sweeping” across multiple exchanges to fill quickly',
    logo: '/images/5fc32382a7287d781649c948_sweeps.png',
  },
  {
    id: 3,
    name: 'Private Blocks',
    desc: 'Substantially large, privately negotiated orders that are too big to be executed through public markets',
    logo: '/images/5fc3238129c813210c2fa256_private.png',
  },
  {
    id: 4,
    name: 'Dark Pool Prints',
    desc: 'Shine the light on trades executed in private exchanges used to hide big movements',
    logo: '/images/5fc32382a013851bb71c5e2e_exchange-p-500.png',
  },
];

const LandingPage: NextPage = () => {
  const showBanner = useShowBannerConditions({
    timeDelay: TIME_DELAY,
    additionalCondition: () => SHOW_BANNER_CONDITION() as true,
  });

  return (
    <>
      <Head
        title="Complete Options Trading Toolkit"
        description="The #1 options flow, dark pool prints, options profit calculator, technical analysis, algorithmic trade ideas, and market-moving news - all in real time. Trade like a pro with InsiderFinance."
        ogImage="https://www.insiderfinance.io/images/hero-dashboard.png"
        useCurrentUrl
      />
      <PageLayout hasPadTop topBanner={<TopBanner />}>
        <TopText heading="Increase your win rate with live options flow and the complete options trading toolkit" />
        <LandingHeroSection />
        <StatsSection stats={stats} />
        <FeaturedSection />
        <AwardBlock />
        <LandingQuote heading={landingQuote} />
        <CallOutSection tabsItems={tabs} heading={calloutHeading} subheading={calloutSubheading} />
        <DifferentiatorSection />
        <TradingSystemTabs />
        <TrackerItems
          statsItem={trackerStats}
          calloutHeading={trackerCalloutHeading}
          heading="See the Trades Smart Money Tries to Hide"
        />
        {/* <DifferentiatorSectionCrypto /> */}
        <Testimonials />

        <PricingCardsComponent
          showBanner={showBanner}
          heading={
            <>
              <span className="font-bold">LIMITED TIME:&nbsp;</span>
              Lock in your lifetime sale price for the
              <span className="font-bold italic">only</span> award-nominated order flow toolkit
            </>
          }
          pricingTiers={PRICING_TIER}
        />
        <div className="text-center">
          <GetInTouch />
        </div>
        <div className="mx-auto max-w-7xl px-4">
          <div className="flex flex-col items-center justify-between gap-4 rounded-2xl border-2 border-electric-teal bg-palette-black-4 p-10 lg:flex-row">
            <h2 className="text-xl">
              <span className="font-bold text-electric-teal">Not ready to subscribe?</span> Try
              before you buy with our free options flow.
            </h2>
            <Link
              href={OPTION_FLOW_PAGE}
              className="flex items-center justify-center rounded-md bg-electric-teal px-6 py-3 text-lg font-bold text-black visited:text-black"
            >
              Use Free Options Flow <ArrowRightIcon className="ml-2 w-[1.125rem]" />
            </Link>
          </div>
        </div>
        <div className="mt-16">
          <PricingGrid rowItems={ROW_NAMES} />
        </div>
        <CommunityPlan />
        <div className="mx-auto mt-16 max-w-7xl px-4">
          <CompetitorComparison />
        </div>
        <NewProduct />
        <NewsLetterBlock />
        <TookitSection heading="You don't have to work at a hedge fund to make money like one. We provide the exact positions Wall Street is trading so you can trade at the same time using any broker or trading app." />
      </PageLayout>
    </>
  );
};

export default LandingPage;
