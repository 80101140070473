import React, { useState } from 'react';
import { useApiGateway } from 'hooks/useApi';
import classNames from 'styles/utils/classNames';
import * as Sentry from '@sentry/nextjs';

const NEWSLETTER_KEY = 'AlertsNewsletter';

export default function NewsLetterForm() {
  const [email, setEmail] = useState('');
  const { post: newsletterSignupFetch, isLoading, isSuccess } = useApiGateway('/v1/email-list');
  const isDisabled = isLoading;

  return (
    <>
      {isSuccess ? (
        <div className="text-pretty rounded-2xl bg-white p-4 text-center font-bold text-green-600">
          Success! You'll receive our free daily newsletter.
        </div>
      ) : (
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            if (isDisabled) return;

            try {
              await newsletterSignupFetch({
                payload: {
                  email,
                  listId: NEWSLETTER_KEY,
                },
              });
            } catch (error) {
              Sentry.captureException(error);
            } finally {
              setEmail('');
            }
          }}
          className="mx-auto flex w-full flex-col gap-4 md:flex-row"
        >
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className={classNames(
              'min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6',
              isDisabled ? 'cursor-not-allowed opacity-50' : '',
            )}
            placeholder="Email..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isDisabled}
          />
          <button
            type="submit"
            className={classNames(
              'w-full flex-none rounded-md bg-brand-primary px-2.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-brand-primary focus-visible:outline focus-visible:outline-2 md:w-48',
              isDisabled ? 'cursor-not-allowed opacity-50' : '',
            )}
            disabled={isDisabled}
          >
            {isDisabled ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      )}
    </>
  );
}
