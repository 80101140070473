import React from 'react';
import NewsLetterForm from './Form';

export default function NewsLetterBlock() {
  return (
    <div className="px-4 py-5">
      <div className="mx-auto max-w-4xl">
        <h3 className="mx-auto mb-1 max-w-md pb-4 text-center font-space text-5xl font-bold">
          FREE Trade Ideas Newsletter
        </h3>
        <div className="border-gradient m-auto h-0.5 w-16 bg-gradient-to-r from-blue-500 to-teal-500"></div>
        <div className="relative mt-10 overflow-hidden border-4 border-brand-primary bg-palette-black-4 p-12  px-6 py-10 shadow-2xl sm:rounded-3xl sm:px-24 sm:py-5 xl:py-12">
          <h4 className="mx-auto max-w-2xl text-center text-base font-semibold tracking-tight text-brand-green-primary">
            HIGH POTENTIAL TRADES SENT DIRECTLY TO YOUR INBOX
          </h4>
          <p className="mx-auto mt-2 max-w-xl text-center text-sm font-bold leading-8">
            Add your email to receive our free daily newsletter. No spam, unsubscribe anytime.
          </p>
          <div className="mt-10">
            <NewsLetterForm />
          </div>
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient
                id="759c1415-0410-454c-8f7c-9a820de03641"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(512 512) rotate(90) scale(512)"
              >
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className="py-5 text-center">
          Want high potential trade ideas sent directly to your email inbox? InsiderFinance is
          launching a free newsletter that provides a daily summary of options flow, dark pool, news
          sentiment, and more!
        </div>
      </div>
    </div>
  );
}
